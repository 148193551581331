// Regular expression to match URLs
// eslint-disable-next-line no-useless-escape
const urlRegex = /(https?:\/\/[^\s\[\]\(\)\.]+(?:\.[^\s\[\]\(\)\.]+)+)/g;
const contactEmail = 'ebusinesscoe@heineken.com';

// Function to convert URLs and highlight email addresses in the text
export const formatTextWithLinks = (text) => {
  // Function to replace email addresses with mailto links
  const replaceEmailsWithLinks = (text) => {
    return text.replace(
      new RegExp(contactEmail.replace('.', '\\.'), 'gi'),
      `<a href="mailto:${contactEmail}">HERE</a>`
    );
  };

  // Replace email addresses with mailto links
  let formattedText = replaceEmailsWithLinks(text);

  // Split text into segments of either plain text or URLs
  const segments = formattedText.split(urlRegex);

  // Map over the segments, adding anchor tags for URLs
  formattedText = segments.map((segment, index) => {
    if (segment.match(urlRegex)) {
      // Return a clickable link for URLs
      return (
        `<a key=${index} href="${segment}" target="_blank" rel="noopener noreferrer">${segment}</a>`
      );
    } else {
      // Return plain text segments
      return segment;
    }
  }).join('');

  // Use dangerouslySetInnerHTML to render the formatted text
  return { __html: formattedText };
};
