import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { formatTextWithLinks } from '../../utils/formatTextWithLinks';
import { pallette } from '../../config/pallette';

import SendIcon from '../Icons/SendIcon';
import CloseIcon from '../Icons/CloseIcon';
import AlertIcon from '../Icons/AlertIcon';
import logo from '../../assets/chatbot_logo.png';

import {
  MessageUser,
  MessageBot,
  MessageError,
  MessageContainer,
  AllMessagesContainer,
  MessageContainerUser,
  MessageContainerBot,
  Input,
  CircleCTA,
  CloseButton,
  ChatContainer,
  InputContainer,
  RetryButton,
  SendButton,
  LogoImage,
  LogoText,
  TopPanel
} from './styles';

const sessionId = Date.now();
const initialBotMessage = {
  sender: 'bot',
  text: `🌟 Welcome to our Center of Excellence Portal! 🌟

  <b>I’m still learning, so please make your search requests as specific as possible!</b>

  I'm GenieBot, your personal guide to all things knowledge and expertise within our organization. Whether you're seeking insights, resources, or solutions, I'm here to assist you on your journey.
  
  To get started, simply type your query or topic of interest, and I'll do my best to provide you with relevant insights and assistance. Don't hesitate to ask anything – no question is too big or too small for GenieBot!
  
  Let's embark on this journey of discovery together. Feel free to ask me anything!
  
  🔍 Happy exploring! 🔍`
}

const ChatbotGPT = () => {
  const [messages, setMessages] = useState([initialBotMessage]);
  const [inputText, setInputText] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [isBotReplying, setIsBotReplying] = useState(false);
  const [isError, setIsError] = useState(false);

  const allMessagesRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (allMessagesRef.current) {
      allMessagesRef.current.scrollTop = allMessagesRef.current.scrollHeight;
    }
  }, [messages, showChat]);

  useEffect(() => {
    if (!isBotReplying) {
      focusInput()
    }
  }, [isBotReplying])

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = async () => {
    if (inputText.trim() === '') return;
    setIsError(false)
    
    const userMessage = { text: inputText, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInputText('');
  
    setIsBotReplying(true);

    axios.post(`${process.env.REACT_APP_API_URL}/ask`, { message: userMessage.text, sessionId })
      .then(response => {
        const botMessages = { text: response.data.response, sender: 'bot' };
        setMessages(prevMessages => [...prevMessages, botMessages]);
      })
      .catch(error => {
        setIsError(true)
        console.error('Error:', error);
      })
      .finally(() => {
        setIsBotReplying(false);
      });
  };

  const retrySendMessage = () => {
    setIsError(false);
    setIsBotReplying(true);
    const messageToRetry = messages[messages.length - 1];
    axios.post(`${process.env.REACT_APP_API_URL}/ask`, { message: messageToRetry.text, sessionId })
    .then(response => {
      const botMessages = { text: response.data.response, sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botMessages]);
    })
    .catch(error => {
      setIsError(true)
      console.error('Error:', error);
    })
    .finally(() => {
      setIsBotReplying(false);
    });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of Enter key
      handleSendMessage(); // Call handleSendMessage function on Enter key press
    }
  };

  const toggleChat = () => {
    setShowChat(!showChat);
    sendMessageToParent(showChat ? 'hide' : 'show')
  };

  const sendMessageToParent = (message) => {
    window.parent.postMessage(message, '*'); // '*' allows sending messages to any origin
  }

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      {!showChat && (
        <CircleCTA onClick={toggleChat}>
          <LogoImage src={logo} alt="My Logo" />
          <LogoText>Bot</LogoText>
        </CircleCTA>
      )}

      {showChat && (
        <ChatContainer>
          <TopPanel>
            <CloseButton onClick={toggleChat}>
              <CloseIcon stroke={pallette.purple} />
            </CloseButton>
          </TopPanel>

          <AllMessagesContainer ref={allMessagesRef}>
            {messages.map((message, index) => (
              <MessageContainer key={index}>
                {message.sender === 'user' ? (
                  <MessageContainerUser>
                    <MessageUser>{message.text}</MessageUser>
                  </MessageContainerUser>
                ) : (
                  <MessageContainerBot>
                    <MessageBot dangerouslySetInnerHTML={formatTextWithLinks(message.text)} />
                  </MessageContainerBot>
                )}
              </MessageContainer>
            ))}
            {isBotReplying && (
              <MessageContainerBot>
                <MessageBot>Bot is typing...</MessageBot>
              </MessageContainerBot>
            )}
            {isError && (
              <MessageContainerBot>
                <MessageError><AlertIcon fill={pallette.white} /> There was an error connecting to bot. Click <RetryButton onClick={() => retrySendMessage()}>HERE</RetryButton> to retry or please come back later.</MessageError>
              </MessageContainerBot>
            )}
          </AllMessagesContainer>

          <InputContainer>
            <Input
              type="text"
              ref={inputRef}
              value={inputText}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              disabled={isBotReplying || isError}
              placeholder="Type your question here"
            />
            <SendButton
              onClick={handleSendMessage}
              disabled={isBotReplying || isError}
            >
              <SendIcon color={pallette.purple} />
            </SendButton>
          </InputContainer>
        </ChatContainer>
      )}
    </>
  );
};

export default ChatbotGPT;
