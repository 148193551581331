import React from 'react';
import './App.css';
import ChatbotGPT from './components/ChatbotGPT';
import GlobalStyle from './config/styles';

function App() {
  const widgetStyles = {
    position: 'fixed',
    bottom: '20px', // Adjust as needed
    right: '20px', // Adjust as needed
    zIndex: '9999', // Adjust as needed
  };

  return (
    <div className="App" style={widgetStyles}>
      <GlobalStyle />
      <ChatbotGPT />
    </div>
  );
}

export default App;
