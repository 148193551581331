import { createGlobalStyle } from 'styled-components';
import { pallette } from './pallette';

const GlobalStyle = createGlobalStyle`
  a {
    color: ${pallette.purple};
    text-decoration: none;
  }
`;

export default GlobalStyle;
