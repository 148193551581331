import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Find all widget divs
const widgetDivs = document.querySelectorAll('.aws-widget');

widgetDivs.forEach(div => {
  ReactDOM.render(
    <React.StrictMode>
      <App symbol={div.dataset.symbol}/>
    </React.StrictMode>,
    div
  );
});

// uncomment to develop locally
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
